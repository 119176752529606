import { A, Navigate, action, redirect } from "@solidjs/router";
import { createSignal, onMount } from "solid-js";
import { customerStore, StoredCustomer } from "../lib/user-store";
const [storedCustomer, setStoredCustomer] = customerStore;
import { track } from "~/lib/customer-io";

export default function Survey() {
  const [currentQuestionOrdinal, setCurrentQuestionOrdinal] = createSignal(1);
  const [customerName, setCustomerName] = createSignal("");
  /*
   track("survey-answer", {
     question_slug: "year-started-developing",
     started_professionally: false,
     year_started: 0,
   });*/

  const submitCityQuestion = action(async (formData: FormData) => {
    const city = String(formData.get("city"));
    track("survey-answer", {
      question_slug: "city-living-in",
      city_living_in: city,
    });

    setCurrentQuestionOrdinal(2);
  }, "submit-city-question-action");

  const submitTopToolsQuestions = action(async (formData: FormData) => {
    const topToolsJoy = String(formData.get("top-tools-joy"));
    track("survey-answer", {
      question_slug: "top-tools-joy",
      tools_top_joy: topToolsJoy,
    });

    setCurrentQuestionOrdinal(3);
  }, "submit-top-tools-joy-question-action");

  const submitCuriousToolQuestion = action(async (formData: FormData) => {
    const toolCuriousAbout = String(formData.get("tool-curious"));
    track("survey-answer", {
      question_slug: "tools-curious",
      tool_curious: toolCuriousAbout,
    });

    setCurrentQuestionOrdinal(-1);
  }, "submit-curious-tool-question-action");

  onMount(() => {
    setCustomerName(storedCustomer.first_name);
  });
  return (
    <main class="text-center mx-auto text-gray-700 p-4">
      <div class="grid grid-cols-1 pt-10 pr-8 pl-8   ">
        {currentQuestionOrdinal() === 1 && (
          <form
            id="city-form"
            class=""
            action={submitCityQuestion}
            method="post"
          >
            <div class={`mb-0 text-left `}>
              <div class="pt-8">
                <div class="max-w-md pb-3  text-left">
                  <div class="pb-2">
                    <label class="text-3xl  font-serif " for="city">
                      🌆 First, {customerName()},
                      <br />
                      what <span class="fff-th">city</span> do you{" "}
                      <span class="fff-th">live</span> in?
                    </label>
                  </div>
                  <input
                    type="text"
                    name="city"
                    autocomplete="address-level2"
                    required
                    class="shadow appearance-none border rounded w-60 my-2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="Lagos, Nigeria"
                  ></input>
                </div>

                <button
                  id="submit-subscribe-form-button"
                  class="fff-button disabled:opacity-75; mr-4"
                  type="submit"
                  style="height:3rem"
                >
                  {"♥ Submit"}
                </button>
                <button
                  class="fff-button disabled:opacity-75"
                  type="button"
                  style="height:3rem"
                  onClick={() => setCurrentQuestionOrdinal(2)}
                >
                  {"Rather won't say"}
                </button>
              </div>
            </div>
          </form>
        )}

        {currentQuestionOrdinal() === 2 && (
          <form
            id="top-tools-joy-form"
            class=""
            method="post"
            action={submitTopToolsQuestions}
          >
            <div class={`mb-0 text-left `}>
              <div class="pt-8">
                <div class="max-w-l pb-3  text-left">
                  <div class="pb-2">
                    <div
                      id="tooltip-tools"
                      role="tooltip"
                      class="absolute z-10 invisible opacity-0 inline-block px-3 py-2 text-sm font-medium text-black transition-opacity duration-0 bg-white rounded-lg shadow-sm  tooltip max-w-52"
                    >
                      <b>Tool</b> can be anything important to you - software,
                      programming language, platform, database, Leuchtturm etc.
                      <div class="tooltip-arrow" data-popper-arrow></div>
                    </div>
                    <label class="text-3xl  font-serif " for="city">
                      <p>
                        What are your{" "}
                        <span class="fff-hl">
                          top 1-3{" "}
                          <span class="text-nowrap">
                            tools
                            <span>
                              <svg
                                id="tools-word"
                                data-tooltip-target="tooltip-tools"
                                data-tooltip-placement="right"
                                class="w-5 h-5 fff-text-yellow inline-block relative -top-4 -left-1"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                fill="currentColor"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm9.008-3.018a1.502 1.502 0 0 1 2.522 1.159v.024a1.44 1.44 0 0 1-1.493 1.418 1 1 0 0 0-1.037.999V14a1 1 0 1 0 2 0v-.539a3.44 3.44 0 0 0 2.529-3.256 3.502 3.502 0 0 0-7-.255 1 1 0 0 0 2 .076c.014-.398.187-.774.48-1.044Zm.982 7.026a1 1 0 1 0 0 2H12a1 1 0 1 0 0-2h-.01Z"
                                  clip-rule="evenodd"
                                />
                              </svg>
                            </span>
                          </span>
                        </span>
                      </p>
                      <p>
                        that you find the most <span class="fff-hl">joy</span>
                      </p>
                      <p>
                        {" "}
                        working in, and <span class="fff-hl">why</span>?
                      </p>
                    </label>
                  </div>
                  <textarea
                    name="top-tools-joy"
                    required
                    class="shadow appearance-none border rounded w-96 h-32 my-2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="Let the answer express who you are - e.g. 'I find joy working in Miro because the infinite workspace inspires my creativity somehow. I also enjoy working in Zed because the extra milliseconds of responsiveness feels so good. And I just love Excel formulas because I am a bit of a pervert.'"
                  ></textarea>
                </div>
                <button
                  class="fff-button disabled:opacity-75; mr-4"
                  type="submit"
                  style="height:3rem"
                >
                  {"♥ Submit"}
                </button>
              </div>
            </div>
          </form>
        )}

        {currentQuestionOrdinal() === 3 && (
          <form
            id="curious-tool-form"
            class=""
            method="post"
            action={submitCuriousToolQuestion}
          >
            <div class={`mb-0 text-left `}>
              <div class="pt-8">
                <div class="max-w-l pb-3  text-left">
                  <div class="pb-2 max-w-md">
                    <label class="text-3xl  font-serif " for="city">
                      What is <span class="fff-hl text-5xl">1</span> tool or
                      technology area that you are{" "}
                      <span class="fff-hl">less familiar</span> with but{" "}
                      <span class="fff-hl">curious</span> to explore, and{" "}
                      <span class="fff-hl">why</span>?
                    </label>
                  </div>
                  <textarea
                    name="tool-curious"
                    required
                    class="shadow appearance-none border rounded w-96 h-40 my-2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="Pick something specific and personal rather than what you think you 'should' learn - e.g. 'I am curious about how to learn to program a Rasperri PI because I want to build a google-calendar-connected pomodoro timer with with an e-ink display.'"
                  ></textarea>
                </div>
                <button
                  class="fff-button disabled:opacity-75; mr-4"
                  type="submit"
                  style="height:3rem"
                >
                  {"♥ Submit"}
                </button>
              </div>
            </div>
          </form>
        )}
        {currentQuestionOrdinal() === -1 && (
          <div class={`px-8 mb-4 max-w-screen-md`}>
            <p class="text-4xl text-white font-serif my-6 pt-8 pr-2 ">
              You are a star, <span class="fff-th">{customerName()}</span>!
            </p>
            <p class="pb-4">
              That is all, you are dismissed and can go out for a walk in the
              sun!
            </p>
          </div>
        )}
      </div>
    </main>
  );
}
